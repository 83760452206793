export default {
  "sidebar.dashboard": "Dashboard",
  "sidebar.dashboard1": "Dashboard 1",
  "sidebar.dashboard2": "Dashboard 2",
  "sidebar.dashboard3": "Dashboard 3",
  "sidebar.fullpagemenu": "Dashboard",
  "sidebar.videoPlayer": "Video Player",
  "sidebar.level1": "Level 1",
  "sidebar.level2": "Level 2",
  "sidebar.level3": "Level 3",
  "sidebar.webanalytics": "Web Analytics",
  "sidebar.saas": "Saas",
  "sidebar.ecommerce": "Ecommerce",
  "sidebar.shop": "Shop",
  "sidebar.cart": "Cart",
  "sidebar.checkout": "Checkout",
  "sidebar.productdetails": "Produkteinzelheiten",
  "sidebar.invoice": "Rechnung",
  "sidebar.ordermanage": "Auftragsmanager",  
  "sidebar.email": "E-Mail",
  "sidebar.calendar": "Kalender",
  "sidebar.payment": "Zahlung",
  "sidebar.signaturePad": "Unterschriften-Pad",
  "sidebar.userManagement": "Nutzerverwaltung",
  "sidebar.profile": "Profil",
  "sidebar.modern": "Modern",
  "sidebar.standard": "Standard",
  "sidebar.style1": "Style 1",
  "sidebar.style2": "Style 2",
  "sidebar.contactGrid": "Kontakt-Raster",
  "sidebar.Resources": "Fachbereich",
  "sidebar.Insurances": "Versicherungen",
  "sidebar.Services": "Leistungen",
  "sidebar.Status": "Status",
  "sidebar.Manages": "Verwaltung",
  "sidebar.extended": "Erweitert",
  "sidebar.userSettings": "Anwender-Einstellungen",
  "sidebar.ausgaben": "Ausgaben",
  "sidebar.pages": "Seiten",
  "sidebar.services": "Leistungen",
  "sidebar.ingredients": "Inhaltsstoffe",
  "sidebar.permissions": "Berechtigungen",
  "sidebar.permissionslist": "Berechtigungsliste",
  "sidebar.roles": "Rollen",
  "sidebar.faq": "FAQ",
  "sidebar.pricing": "Preise",
  "sidebar.pricingv1": "Preise V1",
  "sidebar.pricingv2": "Preise V2",
  "sidebar.pricingUpgrade": "Preis-Upgrade",
  "sidebar.verticalTimeline": "Vertikale Zeitleiste",
  "sidebar.horizontalTimeline": " Horizontale Zeitleiste",
  "sidebar.horizontalStepper": "Horizontaler Stepper",
  "sidebar.verticalStepper": "Vertikaler Stepper",
  "sidebar.blog": "Blog",
  "sidebar.blogGrid": "Blog-Raster",
  "sidebar.blogDetails": "Blog-Details",
  "sidebar.uiComponents": "UI-Komponenten",
  "sidebar.appBar": "App Bar",
  "sidebar.avatars": "Avatar",
  "sidebar.buttons": "Schaltflächen",
  "sidebar.bottomNavigations": "Untere Navigationen",
  "sidebar.chip": "Baustein",
  "sidebar.lists": "Listen",
  "sidebar.modals": "Modals",
  "sidebar.tables": "Tabellen",
  "sidebar.basicTable": "Einfache Tabelle",
  "sidebar.agGrid": "AgGrid",
  "sidebar.searchTable": "Suche in der Tabelle",
  "sidebar.patients": "Patienten",
   "sidebar.maps": "Karten",
  "sidebar.emailAutomation": "E-Mail Automatisierung",
  "sidebar.emailTemplates": "E-Mail Vorlagen",
  "sidebar.emailTrigers": "E-Mail Abläufe",
  "sidebar.medications": "Medikamente",
  "sidebar.doctors": "Ärzte",
  "sidebar.caremanagers": "Pflegemanager",
  "sidebar.document": "Dokument-Editor",
  "sidebar.instances": "Instanzen",
  "sidebar.users": "Nutzer",
  "sidebar.order": "Bestellungen",
  "sidebar.manageOrders": "Aufträge verwalten",
  "sidebar.medication": "Medikamente",
  "sidebar.pharmacies": "Apotheken",
  "sidebar.sessions": "Sitzungen",
  "sidebar.layout1": "Layout 1",
  "sidebar.layout2": "Layout 2",
  "sidebar.signIn": "Login",
  "sidebar.signUp": "Registrierung",
  "sidebar.forgotPassword": "Passwort vergessen",
  "sidebar.errorPages": "Fehler-Seiten",
  "sidebar.basic": "Basic",
  "sidebar.upgrade": "Upgrade",
  "sidebar.fourzerofour": "404",
  "sidebar.fivezerozero": "500",
  "sidebar.timeline": "Zeitleiste",
  "sidebar.stepper": "Stepper",
  "sidebar.fileManager": "Pflegemanager",
  "sidebar.documents": "Dokumente",
  "sidebar.carefolders": "Pflegeordner",
   "sidebar.verordnungs": "Verordnung",
  "widgets.newVisitors": "Neue Besucher",
  "widgets.newSales": "Neue Verkäufe",
  "widgets.bounceRate": "Absprungrate",
  "widgets.earning": "Einnahmen",
  "widgets.QuickLinks": "Schnelle Links",
  "widgets.frequentlyUsedSectiopns": "Häufig benutzte Bereiche",
  "widgets.newUser": "Neue Nutzer",
  "widgets.welcome": "Hallo John, willkommen zurück",
  "widgets.quickStats": "Heutige Schnellstatistiken",
  "widgets.views": "Ansichten",
  "widgets.growth": "Wachstum",
  "widgets.rating": "Bewertung",
  "widgets.userGrowth": "Benutzer % Wachstum",
  "widgets.avgUsers": "Durchschnitts-Benutzer",
  "widgets.avgTime": "Durchschnitts-Zeiten",
  "widgets.customTable": "Angepasste Tabelle",
  "widgets.users": "Nutzer",
  "widgets.revenue": "Einnahmen",
  "widgets.conversionRate": "Konversionsrate",
  "widgets.sessions": "Sitzungen",
  "widgets.totalRevenue": "Gesamteinnahmen",
  "widgets.totalOrders": "Aufträge insgesamt",
  "widgets.totalUsers": "Anwender insgesamt",
  "widgets.overallTrafficStatus": "Gesamter Traffic Status",
  "widgets.liveChat": "Live-Diagramm",
  "widgets.tasksList": "Liste der Aufgaben",
  "widgets.newUsers": "Neue Benutzer",
  "widgets.dynamicBarChart": "Dynamisches Balkendiagramm",
  "widgets.visitorsStats": "Besucher-Statistiken",
  "widgets.recipients": "Begünstigte",
  "widgets.openRate": "Open Rate",
  "widgets.marketing": "Marketing",
  "widgets.sales": "Sales",
  "widgets.topProducts": "Top Products",
  "widgets.trends": "Trends",
  "widgets.analyticsWebSessionsByRegion": "Analytics Web Sessions By Region",
  "widgets.keywordSearch": "Keyword Search",
  "widgets.visitorsDevicesUsed": "Devices Used By Visitors",
  "widgets.howmanyuserscamefromABC?": "How Many Users Came From ABC?",
  "widgets.totalViews": "Total Views",
  "widgets.totalSales": "Total Sales",
  "widgets.viewMore": "View More",
  "widgets.newAccounts": "New Accounts",

  "component.products": "Products",
  "component.signup": "Sign Up",
  "component.loadmore": "Load More",
  "component.categories": "Categories",
  "component.rating": "Rating",
  "component.priceFilter": "Price Filter",
  "component.productDetails": "Product Details",
  "component.backToProducts": "Back To Products",
  "component.backToMedications": "Back To Medications",
  "component.addToCart": "Add To Cart",
  "component.viewCart": "View Cart",
  "component.addtowishlist": "Add To Wishlist",
  "component.yourProductCart": "Your Product Cart",
  "component.continueShopping": "Continue Shopping",
  "component.myShoppingBag": "My Shopping Bag",
  "component.yourBill": "Your Bill",
  "component.saveShoppingBag": "Save Shopping Bag",
  "component.proceedToCheckout": "Proceed To Checkout",
  "component.subtotal": "Subtotal",
  "component.tax": "Tax",
  "component.total": "Total",
  "component.shippingAddress": "Shipping Address",
  "component.payment": "Payment",
  "component.continueToPayment": "Continue To Payment",
  "component.invoice": "Invoice",
  "component.medication_detail": "Detail Medication",
  "component.getMembership": "Get Membership",
  "component.alreadyHaveAccount?": "Already Have Account?",
  "component.continueAsGuest?": "Continue As Guest?",
  "components.compose": "Compose",
  "component.firebase": "Firebase",
  "component.auth0": "Auth 0",
  "component.youtubevideoplayer": "Youtube Video Player",
  "component.vimeovideoplayer": "Vimeo Video Player",

  "module.inbox": "Inbox",
  "module.drafts": "Drafts",
  "module.sent": "Sent",
  "module.trash": "Trash",
  "module.spam": "Spam",
  "module.primary": "Primary",
  "module.social": "Social",
  "module.marketing": "Marketing",

  "component.contactGrid": "Contact Grid",
  "component.addContact": "Add Contact",
  "component.allContacts": "All Contacts",
  "component.recentlyAdded": "Recently Added",
  "component.favourite": "Favourite",
  "component.todaysTasks": "Today's Tasks",
  "component.recentFollowers": "Recent Followers",
  "component.settings": "Settings",
  "component.home": "Home",
  "component.general": "General",
  "component.account": "Account",
  "component.email": "Email",
  "component.faq": "Frequently Asked Questions",
  "component.generalEnquiries": "General Enquiries",
  "component.accounts": "Accounts",
  "component.features": "Features",
  "component.rulesAndPolicies": "Rules And Policies",
  "component.chooseYourPlan": "Choose Your Plan",
  "component.starter": "STARTER",
  "component.professional": "PROFESSIONAL",
  "component.team": "TEAM",
  "component.enterprise": "ENTERPRISE",
  "component.monthly": "MONTHLY",
  "component.yearly": "YEARLY",
  "component.stillHaveQueries": "Still Have Queries ?",
  "component.callUsAnyTime": "Call Us",
  "component.dropUsALine": "Email Us",
  "component.backToPricingV1": "Back To Pricing V1",
  "component.basic": "Basic",
  "component.standard": "Standard",
  "component.premium": "Premium",
  "component.purchasePlan": "Purchase Plan",
  "component.personal": "Personal",
  "component.finish": "Finish",
  "component.accountInformation": "Account Information",
  "component.personalInformation": "Personal Information",
  "component.editBlog": "Edit Blog",
  "component.deleteBlog": "Delete Blog",
  "component.recentBlog": "Recent Blog",
  "component.simpleAppBar": "Simple App Bar",
  "component.appBarWithAPrimarySearchField": "App Bar With A Primary Search Field",
  "component.appBarWithMenu": "App Bar With Menu",
  "component.imagesAvatar": "Images Avatar",
  "component.letterAvatar": "Letter Avatar",
  "component.sizes": "Sizes",
  "component.iconAvatar": "Icon Avatar",
  "component.grouped": "Grouped",
  "component.withBadge": "Avatars With Badge",
  "component.containedButtons": "Contained Buttons",
  "component.textButtons": "Text Buttons",
  "component.outlinedButtons": "Outlined Buttons",
  "component.uploadButton": "Upload Button",
  "component.buttonsWithIconsAndLabel": "Buttons With Icons And Label",
  "component.iconButtons": "Icon Buttons",
  "component.complexButtons": "Complex Buttons",
  "component.bottomNavigation": "Bottom Navigation",
  "component.bottomNavigationWithNoLabel": "Bottom Navigation With No Label",
  "component.outlinedChip": "Outlined Chip",
  "component.chipArray": "Chip Array",
  "component.simpleList": "Simple List",
  "component.nesteList": "Nested List",
  "component.alignListItems": "Align List Items",
  "component.listWithCheckBox": "List With CheckBox",
  "component.listWithSwitch": "List With Switch",
  "component.simpleModal": "Simple Modal",
  "component.transition": "Transition",
  "component.aggridTable": "Ag-Grid Table",
  "component.megaMenu": "Mega Menu",
  "horizontalMenu.general": "General",
  "horizontalMenu.modules": "Modules",
  "horizontalMenu.components": "Components",
  "horizontalMenu.applications": "Applications",
  "component.upgradeYourPlanNow": "Upgrade Your Plan Now",
  "component.addYourDebitCreditCard": "Add Your Debit or Credit Card",
  "component.termsandconditions": "Terms And Conditions",
  "components.paymentGateways": "Payment Gateways",
  "components.connectPaymentGateways": "Connect Payment Gateways",
  "components.tutorials": "Checklist",
  "components.checklist": "Checklist Tutorial",
  "components.welcomeToHulk": "Welcome To Hulk",
  "components.advancedTable": "Advanced Table",
  "components.chatPanel": "Chat Panel",
  "components.signaturePad": "Signature Pad",
  "components.help": "Help",
  "components.clicktoknowmore": "Click to know more",
  "components.clickhereifyouneedanyhelp": "Click here If you need any help"
}
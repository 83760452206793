export default {
   "sidebar.dashboard" : "仪表板",
   "sidebar.dashboard1" : "仪表板1",
   "sidebar.dashboard2" : "仪表板2",
   "sidebar.dashboard3" : "仪表板3",
   "sidebar.videoPlayer" : "视频播放器",
   "sidebar.multilevelmenu": "多级菜单",
   "sidebar.fullpagemenu" : "整页菜单",
   "sidebar.level1": "1级",
   "sidebar.level2": "2级",
   "sidebar.level3": "3级",
   "sidebar.webanalytics": "网页分析",
   "sidebar.saas": "萨斯",
   "sidebar.ecommerce": "电子商务",
   "sidebar.shop": "店",
   "sidebar.cart": "大车",
   "sidebar.checkout": "查看",
   "sidebar.productdetails": "产品详情",
   "sidebar.invoice": "发票",
   "sidebar.signIn": "登入",
   "sidebar.chat": "聊天室",
   "sidebar.email":"电子邮件",
   "sidebar.calendar":"日历",
   "sidebar.payment": "付款",
   "sidebar.signaturePad": "签名板",
   "sidebar.userManagement": "用户管理",
   "sidebar.contactGrid":"接触网",
   "sidebar.userProfile":"用户资料",
   "sidebar.socialFeeds": "社会供稿",
   "sidebar.userSettings": "用户设置",
   "sidebar.pages": "页数",
   "sidebar.faq": "常问问题",
   "sidebar.pricing": "价钱",
   "sidebar.pricingv1" : "定价V1",
   "sidebar.pricingv2": "定价V2",
   "sidebar.pricingUpgrade": "定价升级",
   "sidebar.verticalTimeline": "垂直时间轴",
   "sidebar.horizontalTimeline":" 水平时间线",
   "sidebar.horizontalStepper":"水平步进",
   "sidebar.verticalStepper": "垂直步进",
   "sidebar.blog":"博客",
   "sidebar.blogGrid": "博客网格",
   "sidebar.blogDetails": "博客详细信息",
   "sidebar.uiComponents":"UI组件",
   "sidebar.appBar":"应用栏",
   "sidebar.avatars": "头像",
   "sidebar.buttons": "纽扣",
   "sidebar.bottomNavigations": "底部导航",
   "sidebar.chip": "芯片",
   "sidebar.lists": "清单",
   "sidebar.modals":"模态",
   "sidebar.tables": "桌子",
   "sidebar.basicTable": "基本表",
   "sidebar.agGrid": "农业网格",
   "sidebar.searchTable" : "搜索表",
   "sidebar.patients": "定制表",
   "sidebar.sessions" : "届会",
	"sidebar.layout1":"版式1",
	"sidebar.layout2":"版式2",
   "sidebar.signUp":"注册",
   "sidebar.forgotPassword": "忘记密码",
   "sidebar.errorPages": "错误页面",
   "sidebar.basic" : "基本的",
   "sidebar.upgrade": "升级",
   "sidebar.fourzerofour": "404",
   "sidebar.fivezerozero": "500",
   "sidebar.timeline" : "时间线",
   "sidebar.stepper" : "踏步机",
   "sidebar.profile": "轮廓",
   "sidebar.modern": "现代",
   "sidebar.standard": "标准",
   "sidebar.style1": "样式1",
   "sidebar.style2": "风格2",

   "widgets.newVisitors" : "新访客",
   "widgets.newSales": "新销售",
   "widgets.bounceRate": "跳出率",
   "widgets.earning": "收益",
   "widgets.QuickLinks" : "快速链接",
   "widgets.frequentlyUsedSectiopns": "常用部分",
   "widgets.newUser" : "新用户",
   "widgets.welcome": "约翰，您好，欢迎回来",
   "widgets.quickStats": "当今的快速统计",
   "widgets.views": "观看次数",
   "widgets.growth": "成长性",
   "widgets.rating": "评分",
   "widgets.userGrowth" : "用户百分比增长",
   "widgets.avgUsers" : "平均用户数",
   "widgets.avgTime" : "平均时间",
   "widgets.customTable": "定制表",
   "widgets.users": "用户数",
   "widgets.revenue" : "收入",
   "widgets.conversionRate" : "兑换率",
   "widgets.sessions": "届会",
   "widgets.totalRevenue": "总收入",
   "widgets.totalOrders": "订单总数",
   "widgets.totalUsers": "总用户",
   "widgets.overallTrafficStatus": "总体交通状况",
   "widgets.liveChat": "实时图表",
   "widgets.tasksList" : "任务清单",
   "widgets.newUsers": "新用户",
   "widgets.dynamicBarChart" : "动态条形图",
   "widgets.visitorsStats": "访客统计",
   "widgets.recipients": "收件者",
   "widgets.openRate": "开仓率",
   "widgets.marketing": "市场行销",
   "widgets.sales": "营业额",
   "widgets.topProducts" : "热门产品",
   "widgets.trends": "发展趋势",
   "widgets.analyticsWebSessionsByRegion": "Google Analytics（分析）网络会话（按地区）",
   "widgets.keywordSearch": "关键词搜索",
   "widgets.visitorsDevicesUsed": "访客使用的设备",
   "widgets.howmanyuserscamefromABC?" : "有多少用户来自ABC？",
   "widgets.totalViews": "总浏览",
   "widgets.totalSales": "总销售额",
   "widgets.viewMore": "查看更多",
   "widgets.newAccounts": "新帐户",

   "component.products" : "产品展示",
   "component.signup" : "注册",
   "component.loadmore": "装载更多",
   "component.categories" : "分类目录",
   "component.rating": "评分",
   "component.priceFilter" : "价格筛选",
   "component.productDetails" : "产品详情",
   "component.backToProducts": "返回产品",
   "component.addToCart": "添加到购物车",
   "component.viewCart" : "查看购物车",
   "component.addtowishlist": "加到收藏夹",
   "component.yourProductCart": "您的产品购物车",
   "component.continueShopping": "继续购物",
   "component.myShoppingBag": "我的购物袋",
   "component.yourBill": "您的账单",
   "component.saveShoppingBag" : "保存购物袋",
   "component.proceedToCheckout": "进行结算",   
   "component.subtotal": "小计",
   "component.tax": "税",
   "component.total": "总",
   "component.shippingAddress" : "送货地址",
   "component.payment": "付款",
   "component.continueToPayment" : "继续付款",
   "component.invoice" : "发票",
   "component.getMembership" : "获得会员",
   "component.alreadyHaveAccount?" : "已经有帐号了？",
   "component.continueAsGuest?": "以访客继续？",
	"components.compose": "撰写",
	"component.firebase" : "火力基地",
	"component.auth0" : "认证 0",
	"component.youtubevideoplayer":"YouTube视频播放器",
	"component.vimeovideoplayer":"Vimeo视频播放器",

   "module.inbox": "收件箱",
   "module.drafts": "草稿",
   "module.sent": "已发送",
   "module.trash": "垃圾",
   "module.spam": "垃圾邮件",
   "module.primary" : "主",
   "module.social": "社会的",
   "module.marketing": "市场行销",

   "component.contactGrid" :"接触网",
   "component.addContact": "添加联系人",
   "component.allContacts": "所有联系人",
   "component.recentlyAdded": "最近添加的",
   "component.favourite": "喜爱",
   "component.todaysTasks":"今天的任务",
   "component.recentFollowers" : "最近关注者",
   "component.settings" : "设定值",
   "component.home": "家",
   "component.general" : "一般",
   "component.account": "帐户",
   "component.email": "电子邮件",
   "component.faq": "经常问的问题",
   "component.generalEnquiries" : "通用查询",
   "component.accounts": "帐目",
   "component.features" : "特征",
   "component.rulesAndPolicies" : "规则与政策",
   "component.chooseYourPlan" : "选择您的计划",
   "component.starter" : "起动机",
   "component.professional": "专业的",
   "component.team": "球队",
   "component.enterprise": "企业",
   "component.monthly": "每月",
   "component.yearly": "每年",
   "component.stillHaveQueries": "还有查询吗？",
   "component.callUsAnyTime" : "随时致电我们",
   "component.dropUsALine" : "放我们一条线",
   "component.backToPricingV1" : "返回定价V1",
   "component.basic": "基本的",
   "component.standard": "标准",
   "component.premium": "保费",
   "component.purchasePlan": "购买计划",
   "component.personal" : "个人",
   "component.finish" : "完",
   "component.accountInformation" : "帐户信息",
   "component.personalInformation" : "个人信息",
   "component.editBlog": "编辑博客",
   "component.deleteBlog" : "删除博客",
   "component.recentBlog" : "最近的博客",
   "component.simpleAppBar": "简单的应用栏",
   "component.appBarWithAPrimarySearchField" : "具有主要搜索字段的应用栏",
   "component.appBarWithMenu" : "带有菜单的应用栏",
   "component.imagesAvatar" : "图片头像",
   "component.letterAvatar" : "字母头像",
   "component.sizes" : "尺码",
   "component.iconAvatar" : "图标头像",
   "component.grouped" : "分组",
   "component.withBadge" : "有徽章的化身",
   "component.containedButtons" : "包含的按钮",
   "component.textButtons" : "文字按钮",
   "component.outlinedButtons" : "概述的按钮",
   "component.uploadButton" : "上载按钮",
   "component.buttonsWithIconsAndLabel": "带有图标和标签的按钮",
   "component.iconButtons": "图标按钮",
   "component.complexButtons": "复杂按钮",
   "component.bottomNavigation": "底部导航",
   "component.bottomNavigationWithNoLabel": "没有标签的底部导航",
   "component.outlinedChip": "轮廓芯片",
   "component.chipArray": "芯片阵列",
   "component.simpleList" : "简单清单",
   "component.nesteList": "嵌套清单",
   "component.alignListItems": "对齐列表项",
   "component.listWithCheckBox": "带有复选框的列表",
   "component.listWithSwitch": "带开关清单",
   "component.simpleModal": "简单模态",
   "component.transition": "过渡",
   "component.aggridTable":"Ag网格表",
   "component.megaMenu": "超级菜单",
   "horizontalMenu.general": "一般",
   "horizontalMenu.modules" : "模组",
   "horizontalMenu.components": "组件",
   "horizontalMenu.applications" : "应用领域",
   "component.upgradeYourPlanNow": "立即升级您的计划",
   "component.addYourDebitCreditCard" : "添加您的借记卡或信用卡",
   "component.termsandconditions" : "条款和条件",
   "components.paymentGateways" : "支付网关",
   "components.connectPaymentGateways" : "连接支付网关",
   "components.tutorials": "检查清单",
	"components.checklist": "清单教程",
   "components.welcomeToHulk" : "欢迎来到绿巨人",
   "components.advancedTable" : "高级表",
   "components.chatPanel" : "聊天面板",
   "components.signaturePad": "签名板",
   "components.help" : "救命",
   "components.clicktoknowmore" : "点击了解更多",
   "components.clickhereifyouneedanyhelp" : "单击此处如果您需要任何帮助"
}
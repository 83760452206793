export default {
   "sidebar.dashboard": "Tableau de bord",
   "sidebar.dashboard1": "Tableau de bord 1",
   "sidebar.dashboard2": "Tableau de bord 2",
   "sidebar.dashboard3": "Tableau de bord 3",
   "sidebar.videoPlayer" : "Lecteur vidéo",
   "sidebar.multilevelmenu": "Menu à plusieurs niveaux",
   "sidebar.level1": "Niveau 1",
   "sidebar.level2": "Niveau 2",
   "sidebar.webanalytics": "analyses d'audience Internet",
   "sidebar.saas": "SAAS",
   "sidebar.ecommerce": "Commerce électronique",
   "sidebar.shop": "Boutique",
   "sidebar.cart": "Chariot",
   "sidebar.checkout": "Check-out",
   "sidebar.productdetails": "détails du produit",
   "sidebar.invoice": "facture d'achat",
   "sidebar.chat": "Bavarder",
   "sidebar.email": "Email",
   "sidebar.calendar": "Calendrier",
   "sidebar.payment": "Paiement",
   "sidebar.signaturePad": "Signature Pad",
   "sidebar.userManagement": "Gestion des utilisateurs",
   "sidebar.contactGrid": "Grille de contact",
   "sidebar.userProfile": "Profil de l'utilisateur",
   "sidebar.socialFeeds": "Flux sociaux",
   "sidebar.userSettings": "Paramètres utilisateur",
   "sidebar.pages": "Pages",
   "sidebar.faq": "FAQ",
   "sidebar.pricing": "Prix",
   "sidebar.pricingv1": "Prix ​​V1",
   "sidebar.pricingv2": "Prix ​​V2",
   "sidebar.pricingUpgrade": "Mise à niveau des prix",
   "sidebar.verticalTimeline": "Chronologie verticale",
   "sidebar.horizontalTimeline": "Chronologie horizontale",
   "sidebar.horizontalStepper": "Stepper horizontal",
   "sidebar.verticalStepper": "Stepper vertical",
   "sidebar.blog": "Blog",
   "sidebar.blogGrid": "Grille de blog",
   "sidebar.blogDetails": "Détails du blog",
   "sidebar.uiComponents": "composants ui",
   "sidebar.appBar": "Barre d'application",
   "sidebar.avatars": "Avatars",
   "sidebar.buttons": "Boutons",
   "sidebar.bottomNavigations": "Navigation par le bas",
   "sidebar.chip": "Puce",
   "sidebar.lists": "liste",
   "sidebar.modals": "modaux",
   "sidebar.tables": "les tables",
   "sidebar.basicTable": "Table de base",
   "sidebar.agGrid": "AgGrid",
   "sidebar.searchTable": "table personnalisée",
   "sidebar.patients": "Tableau personnalisé",
   "sidebar.sessions": "Séances",
	"sidebar.layout1":"Disposition 1",
	"sidebar.layout2":"Disposition 2",
   "sidebar.signIn": "Se connecter",
   "sidebar.signUp": "S'inscrire",
   "sidebar.forgotPassword": "Mot de passe oublié",
   "sidebar.errorPages": "Pages d'erreur",
   "sidebar.basic": "De base",
   "sidebar.upgrade": "Améliorer",
   "sidebar.fourzerofour": "404",
   "sidebar.fivezerozero": "500",
   "sidebar.timeline": "Chronologie",
   "sidebar.stepper": "Stepper",
   "sidebar.profile": "Profil",
   "sidebar.modern": "Moderne",
   "sidebar.standard": "la norme",
   "sidebar.style1": "Style 1",
   "sidebar.style2": "Style 2",
   "sidebar.fullpagemenu" : "Menu pleine page",
   
   "widgets.newVisitors": "Nouveaux visiteurs",
   "widgets.newSales": "nouvelles ventes",
   "widgets.bounceRate": "Taux de rebond",
   "widgets.earning": "Revenus",
   "widgets.QuickLinks": "Liens rapides",
   "widgets.frequentlyUsedSectiopns": "Sections fréquemment utilisées",
   "widgets.newUser": "Nouveaux utilisateurs",
   "widgets.welcome": "Salut John, bon retour",
   "widgets.quickStats": "Statistiques rapides du jour",
   "widgets.views": "Vues",
   "widgets.growth": "Croissance",
   "widgets.rating": "Évaluations",
   "widgets.userGrowth": "% D'utilisateurs en croissance",
   "widgets.avgUsers": "Moy. Utilisateurs",
   "widgets.avgTime": "Moy. Temps",
   "widgets.customTable": "Tableau personnalisé",
   "widgets.users": "Utilisateurs",
   "widgets.revenue": "Revenu",
   "widgets.conversionRate": "Taux de conversion",
   "widgets.sessions": "séances",

   "widgets.totalRevenue": "Revenu total",
   "widgets.totalOrders": "Total des commandes",
   "widgets.totalUsers": "Nombre total d'utilisateurs",
   "widgets.overallTrafficStatus": "État général du trafic",
   "widgets.liveChat": "Graphique en direct",
   "widgets.tasksList": "Liste des tâches",
   "widgets.newUsers": "Nouveaux utilisateurs",
   "widgets.dynamicBarChart": "Graphique à barres dynamique",
   "widgets.visitorsStats": "Statistiques des visiteurs",
   "widgets.recipients": "Destinataires",
   "widgets.openRate": "Taux d'ouverture",
   "widgets.marketing": "Commercialisation",
   "widgets.sales": "Ventes",
   "widgets.topProducts": "Produits phares",
   "widgets.trends": "Les tendances",
   "widgets.analyticsWebSessionsByRegion": "Sessions Web Analytics par région",
   "widgets.keywordSearch": "Búsqueda por palabra clave",
   "widgets.visitorsDevicesUsed": "Appareils utilisés par les visiteurs",
   "widgets.howmanyuserscamefromABC?": "Combien d'utilisateurs sont venus d'ABC?",
   "widgets.totalViews": "Vues totales",
   "widgets.totalSales": "Ventes totales",
   "widgets.viewMore": "Voir plus",
   "widgets.newAccounts": "Nouveaux comptes",

   "component.products": "Des produits",
   "component.signup": "S'inscrire",
   "component.loadmore": "charger plus",
   "component.categories": "Les catégories",
   "component.rating": "Évaluation",
   "component.priceFilter": "Filtre de prix",
   "component.productDetails": "détails du produit",
   "component.backToProducts": "Retour aux produits",
   "component.addToCart": "Ajouter au chariot",
   "component.viewCart": "Voir le panier",
   "component.addtowishlist": "Ajouter à la liste de souhaits",
   "component.yourProductCart": "Votre panier de produits",
   "component.continueShopping": "Continuer vos achats",
   "component.myShoppingBag": "Mon panier",
   "component.yourBill": "Votre facture",
   "component.saveShoppingBag": "Enregistrer le panier",
   "component.proceedToCheckout": "Passer à la caisse",
   "component.subtotal": "Total",
   "component.tax": "Impôt",
   "component.total": "Totale",
   "component.shippingAddress": "Adresse de livraison",
   "component.payment": "Paiement",
   "component.continueToPayment": "Continuer vers le paiement",
   "component.invoice": "Facture d'achat",
   "component.getMembership": "Obtenir l'adhésion",
   "component.alreadyHaveAccount?": "Vous avez déjà un compte?",
   "component.continueAsGuest": "Continuer en tant qu'invité?",
	"components.compose": "Composer",
	"component.firebase" : "base de feu",
	"component.auth0" : "Auth 0",
	"component.youtubevideoplayer":"Youtube Video Player",
	"component.vimeovideoplayer":"Lecteur vidéo Vimeo",

   "module.inbox": "Boîte de réception",
   "module.drafts": "Brouillons",
   "module.sent": "Expédié",
   "module.trash": "Poubelle",
   "module.spam": "Spam",
   "module.primary": "Primaire",
   "module.social": "Sociale",
   "module.marketing": "Commercialisation",

   "component.contactGrid": "Grille de contact",
   "component.addContact": "Ajouter le contact",
   "component.allContacts": "Tous les contacts",
   "component.favourite": "Préférée",
   "component.recentlyAdded": "Récemment ajouté",
   "component.todaysTasks": "Tâches d'aujourd'hui",
   "component.recentFollowers": "Abonnés récents",
   "component.settings": "Réglages",
   "component.home": "Accueil",
   "component.account": "Compte",
   "component.general": "Account",
   "component.email": "Email",
   "component.faq": "Questions fréquemment posées",
   "component.generalEnquiries": "Renseignements généraux",
   "component.accounts": "Comptes",
   "component.features": "traits",
   "component.rulesAndPolicies": "Règles et politiques",
   "component.chooseYourPlan": "Choisissez votre plan",
   "component.starter":"ENTRÉE",
   "component.professional": "PROFESSIONNELLE",
   "component.team": "ÉQUIPE",
   "component.enterprise": "ENTREPRISE",
   "component.monthly": "MENSUEL",
   "component.yearly": "ANNUEL",
   "component.stillHaveQueries": "Vous avez toujours des requêtes?",
   "component.callUsAnyTime": "Appelez-nous",
   "component.dropUsALine": "Écrivez-nous",
   "component.backToPricingV1": "Retour à la tarification V1",
   "component.basic": "De base",
   "component.standard": "la norme",
   "component.premium": "Prime",
   "component.purchasePlan": "Plan d'achat",
   "component.personal": "Personnel",
   "component.finish": "terminer",
   "component.accountInformation": "Information sur le compte",
   "component.personalInformation": "Renseignements personnels",
   "component.editBlog": "Modifier le blog",
   "component.deleteBlog": "Supprimer le blog",
   "component.recentBlog": "Blog récent",
   "component.simpleAppBar": "Barre d'application simple",
   "component.appBarWithAPrimarySearchField": "Barre d'application avec un champ de recherche principal",
   "component.appBarWithMenu": "Barre d'application avec menu",
   "component.imagesAvatar": "Images Avatar",
   "component.letterAvatar": "Lettre Avatar",
   "component.sizes": "Tailles",
   "component.iconAvatar": "Icône Avatar",
   "component.grouped": "Groupé",
   "component.withBadge": "Avartars avec badge",
   "component.containedButtons": "Boutons contenus",
   "component.textButtons": "Boutons de texte",
   "component.outlinedButtons": "Boutons décrits",
   "component.uploadButton": "Bouton de téléchargement",
   "component.buttonsWithIconsAndLabel": "Boutons avec icônes et étiquette",
   "component.iconButtons": "Boutons d'icônes",
   "component.complexButtons": "Boutons complexes",
   "component.bottomNavigation": "Navigation en bas",
   "component.bottomNavigationWithNoLabel": "Navigation inférieure sans étiquette",
   "component.outlinedChip": "Puce décrite",
   "component.chipArray": "Chip Array",
   "component.simpleList": "Liste simple",
   "component.nesteList": "Liste imbriquée",
   "component.alignListItems": "Aligner les éléments de la liste",
   "component.listWithCheckBox": "Liste avec case à cocher",
   "component.listWithSwitch": "Liste avec interrupteur",
   "component.simpleModal": "Modal simple",
   "component.transition": "Transition",
   "component.aggridTable": "Table Ag-Grid",
   "component.megaMenu": "Mega Menu",
   "horizontalMenu.general": "Générale",
   "horizontalMenu.modules": "Modules",
   "horizontalMenu.components": "Composantes",
   "horizontalMenu.applications": "Applications", 
   "component.upgradeYourPlanNow": "Améliorez votre plan maintenant",
   "component.addYourDebitCreditCard" : "Ajoutez votre carte de débit ou de crédit",
   "component.termsandconditions" : "Termes et conditions",
   "components.paymentGateways" : "Passerelles de paiement",
   "components.connectPaymentGateways" : "Connecter la passerelle de paiement",
	"components.tutorials": "Liste de contrôle",
	"components.checklist": "Liste de vérification du didacticiel",
   "components.welcomeToHulk" : "Bienvenue à Hulk",
   "components.advancedTable" : "Tableau avancé",
   "components.chatPanel" : "Panneau de discussion",
   "components.signaturePad": "Signature Pad",
   "components.help" : "Aidez-moi",
   "components.clicktoknowmore" : "Cliquez pour en savoir plus",
   "components.clickhereifyouneedanyhelp" : "Cliquez ici si vous avez besoin d'aide"
}
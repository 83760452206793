export default {
   "sidebar.dashboard" : "계기반",
   "sidebar.dashboard1" : "계기반1",
   "sidebar.dashboard2" : "계기반2",
   "sidebar.dashboard3" : "계기반3",
	"sidebar.multilevelmenu": "다단계 메뉴",
   "sidebar.videoPlayer" : "비디오 플레이어",
	"sidebar.fullpagemenu" : "전체 페이지 메뉴",
   "sidebar.level1": "레벨 1",
	"sidebar.level2": "레벨 2",
	"sidebar.level3": "레벨 3",
   "sidebar.webanalytics": "웹 분석",
   "sidebar.saas": "사스",
   "sidebar.ecommerce": "전자 상거래",
   "sidebar.shop": "가게",
   "sidebar.cart": "카트",
   "sidebar.checkout": "점검",
   "sidebar.productdetails": "제품 세부 정보",
   "sidebar.invoice": "송장",
   "sidebar.payment": "지불",
   "sidebar.signaturePad": "서명 패드",
   "sidebar.chat": "잡담",
   "sidebar.email":"이메일",
   "sidebar.calendar":"달력",
   "sidebar.userManagement": "사용자 관리t",
   "sidebar.contactGrid":"컨택트 그리드",
   "sidebar.userProfile":"유저 프로필",
   "sidebar.socialFeeds": "소셜 피드",
   "sidebar.userSettings": "사용자 설정",
   "sidebar.pages": "페이지",
	"sidebar.faq": "자주하는 질문",
	"sidebar.pricing": "가격",
   "sidebar.pricingv1" : "가격 V1",
   "sidebar.pricingv2": "가격 V2",
   "sidebar.pricingUpgrade": "가격 업그레이드",
   "sidebar.verticalTimeline": "수직 타임 라인",
   "sidebar.horizontalTimeline":"수평 타임 라인",
   "sidebar.horizontalStepper":"수평 스테퍼",
   "sidebar.verticalStepper": "수직 스테퍼",
   "sidebar.blog":"블로그",
   "sidebar.blogGrid": "블로그 그리드",
   "sidebar.blogDetails": "블로그 세부 사항",
   "sidebar.uiComponents":"UI 구성 요소",
   "sidebar.appBar":"앱 바",
   "sidebar.avatars": "아바타",
   "sidebar.buttons": "버튼",
   "sidebar.bottomNavigations": "하단 탐색",
   "sidebar.chip": "칩",
   "sidebar.lists": "기울기",
   "sidebar.modals":"모달",
   "sidebar.tables": "테이블",
   "sidebar.basicTable": "기본 테이블",
   "sidebar.agGrid": "AgGrid",
   "sidebar.searchTable" : "검색 테이블",
   "sidebar.patients": "맞춤 표",
   "sidebar.sessions" : "세션",
	"sidebar.layout1":"레이아웃 1",
	"sidebar.layout2":"레이아웃 2",
   "sidebar.signIn": "로그인",
   "sidebar.signUp":"가입하기",
   "sidebar.forgotPassword": "비밀번호를 잊으 셨나요",
   "sidebar.errorPages": "오류 페이지",
   "sidebar.basic" : "기본",
   "sidebar.upgrade": "업그레이드",
   "sidebar.fourzerofour": "404",
   "sidebar.fivezerozero": "500",
   "sidebar.timeline" : "타임 라인",
   "sidebar.stepper" : "스테퍼",
   "sidebar.profile": "프로필",
   "sidebar.modern": "현대",
   "sidebar.standard": "표준",
   "sidebar.style1": "스타일 1",
   "sidebar.style2": "스타일 2",

   "widgets.newVisitors" : "신규 방문자",
   "widgets.newSales": "새로운 판매",
   "widgets.bounceRate": "이탈률",
   "widgets.earning": "수입",
   "widgets.QuickLinks" : "빠른 링크",
   "widgets.frequentlyUsedSectiopns": "자주 사용하는 섹션",
   "widgets.newUser" : "신규 사용자",
   "widgets.welcome": "안녕 존, 다시 오신걸 환영합니다",
   "widgets.quickStats": "오늘의 빠른 통계",
   "widgets.views": "견해",
   "widgets.growth": "성장",
   "widgets.rating": "평가",
   "widgets.userGrowth" : "사용자 % 성장",
   "widgets.avgUsers" : "평균 사용자",
   "widgets.avgTime" : "평균 시각",
   "widgets.customTable": "맞춤 표",
   "widgets.users": "사용자",
   "widgets.revenue" : "수익",
   "widgets.conversionRate" : "전환율",
   "widgets.sessions": "세션",
   "widgets.totalRevenue": "총 수익",
   "widgets.totalOrders": "총 주문",
   "widgets.totalUsers": "총 사용자",
   "widgets.overallTrafficStatus": "전체 교통 상황",
   "widgets.liveChat": "라이브 차트",
   "widgets.tasksList" : "작업 목록",
   "widgets.newUsers": "신규 사용자",
   "widgets.dynamicBarChart" : "동적 막대 차트",
   "widgets.visitorsStats": "방문자 통계",
   "widgets.recipients": "받는 사람",
   "widgets.openRate": "오픈 율",
   "widgets.marketing": "마케팅",
   "widgets.sales": "매상",
   "widgets.topProducts" : "최고 제품",
   "widgets.trends": "트렌드",
   "widgets.analyticsWebSessionsByRegion": "지역별 분석 웹 세션",
   "widgets.keywordSearch": "키워드 검색",
   "widgets.visitorsDevicesUsed": "방문자가 사용하는 장치",
   "widgets.howmanyuserscamefromABC?" : "ABC에서 온 사용자 수는 몇 명입니까?",
   "widgets.totalViews": "총 조회수",
   "widgets.totalSales": "총 매출",
   "widgets.viewMore": "더보기",
   "widgets.newAccounts": "새 계정",

   "component.products" : "제품",
   "component.signup" : "가입하기",
   "component.loadmore": "더로드",
   "component.categories" : "카테고리",
   "component.rating": "평가",
   "component.priceFilter" : "가격 필터",
   "component.productDetails" : "제품 세부 정보",
   "component.backToProducts": "제품으로 돌아 가기",
   "component.addToCart": "장바구니에 담기",
   "component.viewCart" : "장바구니보기",
   "component.addtowishlist": "위시리스트에 추가",
   "component.yourProductCart": "제품 장바구니",
   "component.continueShopping": "쇼핑을 계속",
   "component.myShoppingBag": "내 쇼핑백",
   "component.yourBill": "청구서",
   "component.saveShoppingBag" : "쇼핑백 저장",
   "component.proceedToCheckout": "결제 진행",   
   "component.subtotal": "소계",
   "component.tax": "세",
   "component.total": "합계",
   "component.shippingAddress" : "배송 주소",
   "component.payment": "지불",
   "component.continueToPayment" : "지불 계속하기",
   "component.invoice" : "송장",
   "component.getMembership" : "회원 가입",
   "component.alreadyHaveAccount?" : "이미 계정이 있습니까?",
   "component.continueAsGuest?": "손님으로 계속?",
	"components.compose": "짓다",
	"component.firebase" : "중포 기지",
	"component.auth0" : "인증 0",
	"component.youtubevideoplayer":"유튜브 비디오 플레이어",
	"component.vimeovideoplayer":"Vimeo 비디오 플레이어",

   "module.inbox": "받은 편지함",
   "module.drafts": "체커",
   "module.sent": "보냄",
   "module.trash": "폐물",
   "module.spam": "스팸",
   "module.primary" : "일 순위",
   "module.social": "사회적인",
   "module.marketing": "마케팅",

   "component.contactGrid" :"컨택트 그리드",
   "component.addContact": "주소록에 추가",
   "component.allContacts": "모든 연락처",
   "component.recentlyAdded": "최근에 추가",
   "component.favourite": "인기 있는 말",
   "component.todaysTasks":"오늘의 임무",
   "component.recentFollowers" : "최근 팔로워",
   "component.settings" : "설정",
   "component.home": "집",
   "component.general" : "일반",
   "component.account": "계정",
   "component.email": "이메일",
   "component.faq": "자주 묻는 질문",
   "component.generalEnquiries" : "일반적인 문의",
   "component.accounts": "계정",
   "component.features" : "풍모",
   "component.rulesAndPolicies" : "규칙과 정책",
   "component.chooseYourPlan" : "요금제 선택",
   "component.starter" : "기동기",
   "component.professional": "전문적인",
   "component.team": "팀",
   "component.enterprise": "기업",
   "component.monthly": "월간 간행물",
   "component.yearly": "매년",
   "component.stillHaveQueries": "여전히 쿼리가 있습니까?",
   "component.callUsAnyTime" : "언제든지 전화",
   "component.dropUsALine" : "이메일",
   "component.backToPricingV1" : "V1 가격으로 돌아 가기",
   "component.basic": "기본",
   "component.standard": "표준",
   "component.premium": "프리미엄",
   "component.purchasePlan": "구매 계획",
   "component.personal" : "개인적인",
   "component.finish" : "끝",
   "component.accountInformation" : "계정 정보",
   "component.personalInformation" : "개인 정보",
   "component.editBlog": "블로그 편집",
   "component.deleteBlog" : "블로그 삭제",
   "component.recentBlog" : "최근 블로그",
   "component.simpleAppBar": "간단한 앱 바",
   "component.appBarWithAPrimarySearchField" : "기본 검색 필드가있는 앱 바",
   "component.appBarWithMenu" : "메뉴가있는 앱 바",
   "component.imagesAvatar" : "이미지 아바타",
   "component.letterAvatar" : "편지 아바타",
   "component.sizes" : "크기",
   "component.iconAvatar" : "아이콘 아바타",
   "component.grouped" : "그룹화",
   "component.withBadge" : "배지와 아바타",
   "component.containedButtons" : "포함 된 버튼",
   "component.textButtons" : "텍스트 버튼",
   "component.outlinedButtons" : "개요 버튼",
   "component.uploadButton" : "업로드 버튼",
   "component.buttonsWithIconsAndLabel": "아이콘 및 레이블 단추",
   "component.iconButtons": "아이콘 버튼",
   "component.complexButtons": "복잡한 버튼",
   "component.bottomNavigation": "하단 탐색",
   "component.bottomNavigationWithNoLabel": "라벨이없는 하단 탐색",
   "component.outlinedChip": "윤곽선 칩",
   "component.chipArray": "칩 어레이",
   "component.simpleList" : "간단한 목록",
   "component.nesteList": "중첩 된 목록",
   "component.alignListItems": "목록 항목 정렬",
   "component.listWithCheckBox": "CheckBox로 목록",
   "component.listWithSwitch": "스위치 목록",
   "component.simpleModal": "간단한 모달",
   "component.transition": "전이",
   "component.aggridTable":"Ag- 그리드 테이블",
   "component.megaMenu": "메가 메뉴",
   "horizontalMenu.general": "일반",
   "horizontalMenu.modules" : "모듈",
   "horizontalMenu.components": "구성 요소",
   "horizontalMenu.applications" : "응용",
   "component.upgradeYourPlanNow": "지금 플랜 업그레이드",
   "component.addYourDebitCreditCard" : "직불 카드 또는 신용 카드 추가",
   "component.termsandconditions" : "이용 약관",
   "components.paymentGateways" : "결제 게이트웨이",
   "components.connectPaymentGateways" : "결제 게이트웨이 연결",
   "components.tutorials": "점검표",
	"components.checklist": "체크리스트 튜토리얼",
   "components.welcomeToHulk" : "헐크에 오신 것을 환영합니다",
   "components.advancedTable" : "고급 테이블",
   "components.chatPanel" : "채팅 패널",
   "components.signaturePad": "서명 패드",
   "components.help" : "도움",
   "components.clicktoknowmore" : "자세한 내용을 보려면 클릭하십시오",
   "components.clickhereifyouneedanyhelp" : "도움이 필요하시면 여기를 클릭하십시오"
}